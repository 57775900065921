import logo from './logo.svg';
import './App.css';
import React, { useRef, useState, useEffect } from 'react';



function handleClick() {
  window.open('https://www.disegnowebologna.com/presupuesto/index1.html');
}

function handleClick1() {
  window.open('whatsapp.php');
}


function AudioPlayer() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="speaker">
      <audio ref={audioRef} hidden controls>
        <source src="music/Romanian.mp3" type="audio/mp3" />
        <source src="music/Romanian.ogg" type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
      <button onClick={handlePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
    </div>
  );
}


function App() {
	const App = window.App || {};

	  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      var App = App || {};
      App.PAGE_ID = "index";
      App.LANG = "es";
      App.BASEURL = "/";
      App.VERSION = new Date().getTime();
      App.SUBMITTEDFORM = false;
      App.VERSION = "0.6";
    `;
    document.body.appendChild(script);

    // Limpiar el script si el componente se desmonta
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
		<div>
		  <div id="preloader">
			<div id="status">&nbsp;</div>
		  </div>
          
		  <main>
			<div id="page">
			  <div className="dwb" id="nonparallax" />
			  <div className="dwb" id="parallax">
				<div className="dwb_layer parallax" id="dwb-0" data-speed={2} />
				<div className="dwb_layer parallax" id="dwb-1" data-speed={5} />
				<div className="dwb_layer parallax x1" id="dwb-2" data-speed={11} />
				<div className="dwb_layer parallax" id="dwb-3" data-speed={16} />
				<div className="dwb_layer parallax" id="dwb-4" data-speed={26} />
				<div className="dwb_layer parallax" id="dwb-5" data-speed={36} />
				<div id="birds-canvas-holder"></div>
				<div className="dwb_layer parallax" id="dwb-6" data-speed={49} />
				<div className="dwb_layer" id="dwb-scrim" />
				<div className="dwb_layer parallax" id="dwb-7" data-speed={69} />
				<div className="dwb_layer" id="particles-js" />
				<div
				  className="dwb_layer"
				  id="slogan-svg"
				  style={{ width: 380, left: "40%", top: 20, display: "none" }}
				/>
				<div className="dwb_layer" id="dwb-8" data-speed={100} />
				<div className="dwb_layer cloud-padding">
				  <div className="sacar" style={{ paddingTop: 60 }}>
					<p className="cloud-title2">
					  living dreams :) <br />_
					</p>
					<img
					  src="images/scroll_down.gif"
					  style={{ width: 80, paddingTop: 30 }}
					/>
				  </div>
				</div>
				
				 <AudioPlayer />
			  </div>
			  <div id="maincontain">
				<div id="main">
				  <div className="section buy">
					<img
					  className="image-jcmr"
					  src="assets/images/juan_carlos_mancha_ramos.png"
					  style={{ textAlign: "center", maxHeight: 200, marginBottom: 20 }}
					/>
					<br />
					<h2 className="banner">
					  <span>
						<em>Juan Carlos </em> Mancha Ramos
					  </span>
					</h2>
					<br />
					<h4>
					  <span>
						<em>Front-end </em> Developer / <em>Graphic </em> designer /{" "}
						<em> Technical</em> SEO
					  </span>
					</h4>
					<br />
					<br />
				  </div>
				  <section id="abt-lnk" className="center-first">
					<div className="gp no-tpgp btm-spc130">
					  <div className="container">
						<div className="skls-wrp2 ovrlp-60 text-center">
						  <div className="skls-inr">
							<h2 itemProp="headline">
							  "Math is easy; design is hard"<span>. Jeffrey Veen</span>
							</h2>
							<div
							  style={{
								maxWidth: 800,
								margin: "0 auto",
								padding: 20,
								textAlign: "justify"
							  }}
							>
							  <p>
								¡Hola! Soy <b>Juan Carlos Mancha Ramos</b>, un
								entusiasta del diseño, la tecnología y todo lo que se
								interponga entre ellos. Me considero un creador
								incansable, siempre buscando nuevas formas de fusionar
								la <b>belleza visual con la funcionalidad</b> para crear
								experiencias digitales memorables.
							  </p>
							  <p>
								<q>Math is easy; design is hard</q>, como dijo una vez
								Jeffrey Veen. Esta frase resuena conmigo, porque cada
								diseño es un desafío único que requiere{" "}
								<b>
								  creatividad, atención al detalle y un toque de magia
								</b>{" "}
								para llevarlo de bueno a excepcional.
							  </p>
							  <p>
								Con mi experiencia como <b>Front-End Developer</b> y{" "}
								<b>Graphic Designer</b>, he tenido el privilegio de
								trabajar en una variedad de proyectos emocionantes.
								Desde el diseño de interfaces intuitivas hasta la
								creación de elementos gráficos llamativos, me apasiona
								cada aspecto del proceso creativo.
							  </p>
							  <p>
								Pero mi pasión no se detiene en el diseño visual.
								También tengo un ojo entrenado para la optimización
								técnica. Con un enfoque meticuloso en{" "}
								<b>Technical SEO</b>, he trabajado para mejorar la
								visibilidad y el rendimiento de numerosos sitios web.
								Creo firmemente que un diseño hermoso es aún más
								impactante cuando se combina con un sólido{" "}
								<b>SEO técnico</b>.
							  </p>
							  <p>
								Con un porcentaje de dominio en cada una de mis
								habilidades, estoy comprometido a seguir creciendo y
								aprendiendo en esta industria en constante evolución.
								Siempre estoy buscando nuevas oportunidades para
								desafiarme a mí mismo y superar las expectativas.
							  </p>
							  <p>
								Estoy emocionado por la posibilidad de colaborar en
								proyectos apasionantes y contribuir con mi experiencia y
								creatividad a tu equipo. ¡Espero tener la oportunidad de
								conocerte y trabajar juntos!
							  </p>
							</div>
						  </div>
						  <div className="skls-brs text-left">
							<div className="row">
							  <div className="col-md-6 col-sm-6 col-lg-6">
								<div className="skl-bx styl2">
								  <span>CMS / HTML / CSS</span> <i>100%</i>
								  <div className="progress rd5">
									<div
									  className="progress-bar blu-bg rd5 slideInLeft wow"
									  data-wow-duration=".4s"
									  data-wow-delay=".3s"
									  style={{
										width: "100%",
										visibility: "visible",
										animationDuration: "0.4s",
										animationDelay: "0.3s",
										animationName: "slideInLeft"
									  }}
									/>
								  </div>
								</div>
							  </div>
							  <div className="col-md-6 col-sm-6 col-lg-6">
								<div className="skl-bx styl2">
								  <span>Javascript / PHP</span> <i>73%</i>
								  <div className="progress rd5">
									<div
									  className="progress-bar blu-bg rd5 slideInLeft wow"
									  data-wow-duration=".4s"
									  data-wow-delay=".4s"
									  style={{
										width: "73%",
										visibility: "visible",
										animationDuration: "0.4s",
										animationDelay: "0.4s",
										animationName: "slideInLeft"
									  }}
									/>
								  </div>
								</div>
							  </div>
							  <div className="col-md-6 col-sm-6 col-lg-6">
								<div className="skl-bx styl2">
								  <span>DISEÑO GRÁFICO</span> <i>93%</i>
								  <div className="progress rd5">
									<div
									  className="progress-bar blu-bg rd5 slideInLeft wow"
									  data-wow-duration=".4s"
									  data-wow-delay=".5s"
									  style={{
										width: "93%",
										visibility: "visible",
										animationDuration: "0.4s",
										animationDelay: "0.5s",
										animationName: "slideInLeft"
									  }}
									/>
								  </div>
								</div>
							  </div>
							  <div className="col-md-6 col-sm-6 col-lg-6">
								<div className="skl-bx styl2">
								  <span>TECHNICAL SEO</span> <i>74%</i>
								  <div className="progress rd5">
									<div
									  className="progress-bar blu-bg rd5 slideInLeft wow"
									  data-wow-duration=".4s"
									  data-wow-delay=".6s"
									  style={{
										width: "74%",
										visibility: "visible",
										animationDuration: "0.4s",
										animationDelay: "0.6s",
										animationName: "slideInLeft"
									  }}
									/>
								  </div>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </section>
				  <section>
					<div className="gp no-tpgp" style={{ display: "none" }}>
					  <div className="container">
						<h2 className="banner">
						  <span>
							<em>Algunos</em> proyectos
						  </span>
						</h2>
						<br />
						<div className="tm-car-wrp">
						  <div className="tm-car owl-carousel owl-theme owl-loaded">
							<div
							  className="owl-stage-outer owl-height"
							  style={{ height: 355 }}
							>
							  <div
								className="owl-stage"
								style={{
								  transform: "translate3d(-1200px, 0px, 0px)",
								  transition: "0.6s",
								  width: 3900
								}}
							  >
								<div
								  className="owl-item cloned"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http://www.box-web.com.ar"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-2.jpg"
										alt="tm-2-2.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http://www.box-web.com.ar"
										  title=""
										  itemProp="url"
										>
										  BOX WEB
										</a>
									  </h4>
									  <span>Diseño Web en Carlos Paz</span>
									  <p itemProp="description">
										Diseño grafico, marketing digital, fotografia
										digital, etc
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item active"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http//saac.gestarcoop.com"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-1.jpg"
										alt="tm-2-1.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http//saac.gestarcoop.com"
										  title=""
										  itemProp="url"
										>
										  SAAC
										</a>
									  </h4>
									  <span>Página de producto de GestarCOOP</span>
									  <p itemProp="description">
										Pagina del aplicativo SAAC, actualmente en
										remodelación
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item active"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http://www.spheragroup.it/"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-3.jpg"
										alt="tm-2-3.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http://www.spheragroup.it/"
										  title=""
										  itemProp="url"
										>
										  SPHERA GROUP
										</a>
									  </h4>
									  <span>Consulenza strategica</span>
									  <p itemProp="description">
										Marketing estratégico para empresas y bolsa de
										empleo en Italia
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item active"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  href="http://www.e-puerto.com"
									  target="_blank"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-4.jpg"
										alt="tm-2-4.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  href="http://www.e-puerto.com"
										  title=""
										  itemProp="url"
										>
										  UNEPOSC
										</a>
									  </h4>
									  <span>Puerto Deseado</span>
									  <p itemProp="description">
										Unidad portuaria de Santa Cruz
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http://www.lucagennasi.com/"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-5.jpg"
										alt="tm-2-5.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http://www.lucagennasi.com/"
										  title=""
										  itemProp="url"
										>
										  LUCA GENNASI
										</a>
									  </h4>
									  <span>Pagina Personal</span>
									  <p itemProp="description">
										Pagina adaptativa personal tipo CV online
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item cloned"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http//saac.gestarcoop.com"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-1.jpg"
										alt="tm-2-1.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http//saac.gestarcoop.com"
										  title=""
										  itemProp="url"
										>
										  SAAC
										</a>
									  </h4>
									  <span>Página de producto de GestarCOOP</span>
									  <p itemProp="description">
										Pagina del aplicativo SAAC, actualmente en
										remodelación
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item cloned"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  target="_blank"
									  href="http://www.spheragroup.it/"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-3.jpg"
										alt="tm-2-3.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  target="_blank"
										  href="http://www.spheragroup.it/"
										  title=""
										  itemProp="url"
										>
										  SPHERA GROUP
										</a>
									  </h4>
									  <span>Consulenza strategica</span>
									  <p itemProp="description">
										Marketing estratégico para empresas y bolsa de
										empleo en Italia
									  </p>
									</div>
								  </div>
								</div>
								<div
								  className="owl-item cloned"
								  style={{ width: 270, marginRight: 30 }}
								>
								  <div className="tm-itm text-center">
									<a
									  href="http://www.e-puerto.com"
									  target="_blank"
									  title=""
									  itemProp="url"
									>
									  <img
										src="assets/images/resources/tm-2-4.jpg"
										alt="tm-2-4.jpg"
										itemProp="image"
									  />
									</a>
									<div className="tm-inf">
									  <h4 itemProp="headline">
										<a
										  href="http://www.e-puerto.com"
										  title=""
										  itemProp="url"
										>
										  UNEPOSC
										</a>
									  </h4>
									  <span>Puerto Deseado</span>
									  <p itemProp="description">
										Unidad portuaria de Santa Cruz
									  </p>
									</div>
								  </div>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </section>
				  <section
					style={{
					  background: "url(/fondo-jcmr.svg), url(/fondo2.svg)",
					  zIndex: 0,
					  backgroundPosition: "top, 54% 59%",
					  backgroundSize: "auto, 500%",
					  backgroundRepeat: "no-repeat, no-repeat"
					}}
				  >
					<div style={{ width: 250, textAlign: "center", margin: "0 auto" }}>
					  <svg
						version="1.1"
						id="Layer_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlnsXlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 500 500"
						style={{ enableBackground: "new 0 0 500 500" }}
						xmlSpace="preserve"
					  >
						<style
						  type="text/css"
						  dangerouslySetInnerHTML={{
							__html:
							  "\n                                        .st0 {\n                                            clip-path: url(#mask_1_);\n                                        }\n                                        .st1 {\n                                            fill: none;\n                                            stroke: #0f0f0f;\n                                            stroke-width: 10;\n                                            stroke-miterlimit: 10;\n                                        }\n                                        .st2 {\n                                            fill: #ffaf00;\n                                        }\n                                        .st3 {\n                                            fill: #e3e3e3;\n                                        }\n                                        .st4 {\n                                            fill: #333333;\n                                        }\n                                        .st5 {\n                                            fill: #bababa;\n                                        }\n                                        .st6 {\n                                            fill: #dd2a6b;\n                                            stroke: #e3e3e3;\n                                            stroke-width: 4;\n                                            stroke-miterlimit: 10;\n                                        }\n                                        .st7 {\n                                            fill: #f39323;\n                                        }\n                                        .star {\n                                            fill: #ffffff;\n                                        }\n                                    "
						  }}
						/>
						<g>
						  <defs>
							<circle id="mask" cx={250} cy={250} r="196.5" />
						  </defs>
						  <use
							xlinkHref="#mask"
							style={{ overflow: "visible", fill: "#000000" }}
						  />
						  <clipPath id="mask_1_">
							<use xlinkHref="#mask" style={{ overflow: "visible" }} />
						  </clipPath>
						  <g id="linePattern" className="st0">
							<line
							  className="st1"
							  x1="-186.2"
							  y1={183}
							  x2={317}
							  y2="686.2"
							/>
							<line
							  className="st1"
							  x1="-167.7"
							  y1="164.6"
							  x2="335.4"
							  y2="667.7"
							/>
							<line
							  className="st1"
							  x1="-149.2"
							  y1="146.1"
							  x2="353.9"
							  y2="649.2"
							/>
							<line
							  className="st1"
							  x1="-130.8"
							  y1="127.7"
							  x2="372.3"
							  y2="630.8"
							/>
							<line
							  className="st1"
							  x1="-112.3"
							  y1="109.2"
							  x2="390.8"
							  y2="612.3"
							/>
							<line
							  className="st1"
							  x1="-93.9"
							  y1="90.7"
							  x2="409.3"
							  y2="593.9"
							/>
							<line
							  className="st1"
							  x1="-75.4"
							  y1="72.3"
							  x2="427.7"
							  y2="575.4"
							/>
							<line
							  className="st1"
							  x1="-56.9"
							  y1="53.8"
							  x2="446.2"
							  y2="556.9"
							/>
							<line
							  className="st1"
							  x1="-38.5"
							  y1="35.4"
							  x2="464.6"
							  y2="538.5"
							/>
							<line
							  className="st1"
							  x1={-20}
							  y1="16.9"
							  x2="483.1"
							  y2={520}
							/>
							<line
							  className="st1"
							  x1="-1.6"
							  y1="-1.6"
							  x2="501.6"
							  y2="501.6"
							/>
							<line
							  className="st1"
							  x1="16.9"
							  y1={-20}
							  x2={520}
							  y2="483.1"
							/>
							<line
							  className="st1"
							  x1="35.4"
							  y1="-38.5"
							  x2="538.5"
							  y2="464.6"
							/>
							<line
							  className="st1"
							  x1="53.8"
							  y1="-56.9"
							  x2="556.9"
							  y2="446.2"
							/>
							<line
							  className="st1"
							  x1="72.3"
							  y1="-75.4"
							  x2="575.4"
							  y2="427.7"
							/>
							<line
							  className="st1"
							  x1="90.7"
							  y1="-93.9"
							  x2="593.9"
							  y2="409.3"
							/>
							<line
							  className="st1"
							  x1="109.2"
							  y1="-112.3"
							  x2="612.3"
							  y2="390.8"
							/>
							<line
							  className="st1"
							  x1="127.7"
							  y1="-130.8"
							  x2="630.8"
							  y2="372.3"
							/>
							<line
							  className="st1"
							  x1="146.1"
							  y1="-149.2"
							  x2="649.2"
							  y2="353.9"
							/>
							<line
							  className="st1"
							  x1="164.6"
							  y1="-167.7"
							  x2="667.7"
							  y2="335.4"
							/>
							<line
							  className="st1"
							  x1={183}
							  y1="-186.2"
							  x2="686.2"
							  y2={317}
							/>
						  </g>
						  <g className="st0">
							<circle className="star" cx={280} cy={10} r={6}>
							  <animate
								attributeName="cx"
								attributeType="XML"
								from={280}
								to={10}
								begin="0s"
								dur="3s"
								fill="remove"
								repeatCount="indefinite"
							  />
							  <animate
								attributeName="cy"
								attributeType="XML"
								from={10}
								to={280}
								begin="0s"
								dur="3s"
								fill="remove"
								repeatCount="indefinite"
							  />
							</circle>
							<circle className="star" cx={490} cy={180} r={4}>
							  <animate
								attributeName="cx"
								attributeType="XML"
								from={490}
								to={180}
								begin="0s"
								dur="5.5s"
								fill="remove"
								repeatCount="indefinite"
							  />
							  <animate
								attributeName="cy"
								attributeType="XML"
								from={180}
								to={490}
								begin="0s"
								dur="5.5s"
								fill="remove"
								repeatCount="indefinite"
							  />
							</circle>
							<circle className="star" cx={400} cy={10} r={3}>
							  <animate
								attributeName="cx"
								attributeType="XML"
								from={400}
								to={10}
								begin="0s"
								dur="8s"
								fill="remove"
								repeatCount="indefinite"
							  />
							  <animate
								attributeName="cy"
								attributeType="XML"
								from={10}
								to={400}
								begin="0s"
								dur="8s"
								fill="remove"
								repeatCount="indefinite"
							  />
							</circle>
							<g id="rocket">
							  <polygon
								className="st2"
								points="206.9,284.1 140,600 -104,360 "
							  />
							  <polygon
								className="st3"
								points="318.4,172.3 291.9,270.7 265.5,369.1 208.3,282.3 121.5,225.2 220,198.7 "
							  />
							  <polygon
								className="st4"
								points="169.5,293.7 179.5,256.1 217.1,246 244.7,273.5 234.6,311.1 197,321.2 "
							  />
							  <g>
								<path
								  className="st3"
								  d="M193.6,297.6c0.2,0.2,0.3,0.3,0.5,0.5C193.8,297.8,193.7,297.6,193.6,297.6C193.5,297.5,193.5,297.5,193.6,297.6z"
								/>
								<path
								  className="st3"
								  d="M350.3,203.2c9.6-34.9,11.8-67.7,8.5-71c-3.3-3.3-35.7-1.1-70.5,8.4c13.8,4.8,26.7,12.7,37.8,23.8C337.5,175.8,345.5,189.1,350.3,203.2z"
								/>
								<path
								  className="st5"
								  d="M313.9,275.5c17.1-17.1,28.9-45.3,36.3-72.2c-4.7-14.1-12.8-27.5-24.1-38.8c-11.1-11.1-24.1-19-37.8-23.8c-27.1,7.4-55.5,19.3-72.7,36.5c-39.3,39.3-49.2,93.1-22.2,120.3c0.2,0.2,0.4,0.4,0.6,0.6C221.3,324.6,274.8,314.6,313.9,275.5z"
								/>
							  </g>
							  <ellipse
								transform="matrix(0.7071 -0.7071 0.7071 0.7071 -64.33 260.765)"
								className="st6"
								cx="282.6"
								cy={208}
								rx="30.4"
								ry="30.4"
							  />
							  <animateMotion
								path="M10,-8 C 12,-8 14,-12 12,-8 Z"
								begin="0s"
								dur="1s"
								repeatCount="indefinite"
							  />
							</g>
							<g id="cloud1">
							  <path
								className="st7"
								d="M33.5,424.6c-24.8,0-47.9-11.8-62.5-31.7c-15.4-4.3-29.4-13.3-39.5-25.8c-11.2-13.8-17.4-31.1-17.4-48.9 c0-12.6,3.1-25,8.9-36.1c-1.4-5.5-2.1-11.2-2.1-17c0-37.7,30.7-68.4,68.4-68.4c4.4,0,8.9,0.4,13.2,1.3c9.8-4.3,20.3-6.5,31.1-6.5 c28.5,0,54.9,15.9,68.4,40.9c25.9,13.2,42.4,39.9,42.4,69.1c0,26.5-13.6,51.1-35.8,65.3C99.6,400.6,68.8,424.6,33.5,424.6z"
							  />
							  <path
								id="cloud2"
								className="st2"
								d="M134.3,301.5c0-27.4-16.3-51-39.8-61.6c-10.9-22.7-34.1-38.5-61-38.5c-10.8,0-20.9,2.5-30,7 c-4.6-1.2-9.4-1.8-14.4-1.8c-32.2,0-58.4,26.1-58.4,58.4c0,6.4,1,12.5,2.9,18.2c-6.1,10.2-9.7,22.1-9.7,34.8 c0,32.3,22.7,59.3,53,66c12.1,18.4,32.8,30.5,56.5,30.5c32.7,0,60-23.2,66.2-54.1C120.4,348.9,134.3,326.9,134.3,301.5z"
							  />
							  <animateTransform
								attributeName="transform"
								attributeType="XML"
								type="rotate"
								from="360 20 300"
								to="0 20 300"
								begin="0s"
								dur="5s"
								repeatCount="indefinite"
								additive="sum"
							  />
							</g>
							<g id="cloud2">
							  <path
								className="st7"
								d="M200.5,573.6c-24.8,0-47.9-11.8-62.5-31.7c-15.4-4.3-29.4-13.3-39.5-25.8c-11.2-13.8-17.4-31.1-17.4-48.9 c0-12.6,3.1-25,8.9-36.1c-1.4-5.5-2.1-11.2-2.1-17c0-37.7,30.7-68.4,68.4-68.4c4.4,0,8.9,0.4,13.2,1.3c9.8-4.3,20.3-6.5,31.1-6.5 c28.5,0,54.9,15.9,68.4,40.9c25.9,13.2,42.4,39.9,42.4,69.1c0,26.5-13.6,51.1-35.8,65.3C266.6,549.6,235.8,573.6,200.5,573.6z"
							  />
							  <path
								id="cloud1"
								className="st2"
								d="M301.3,450.5c0-27.4-16.3-51-39.8-61.6c-10.9-22.7-34.1-38.5-61-38.5c-10.8,0-20.9,2.5-30,7 c-4.6-1.2-9.4-1.8-14.4-1.8c-32.2,0-58.4,26.1-58.4,58.4c0,6.4,1,12.5,2.9,18.2c-6.1,10.2-9.7,22.1-9.7,34.8 c0,32.3,22.7,59.3,53,66c12.1,18.4,32.8,30.5,56.5,30.5c32.7,0,60-23.2,66.2-54.1C287.4,497.9,301.3,475.9,301.3,450.5z"
							  />
							  <animateTransform
								attributeName="transform"
								attributeType="XML"
								type="rotate"
								from="0 200 450"
								to="360 200 450"
								begin="0s"
								dur="5s"
								repeatCount="indefinite"
								additive="sum"
							  />
							</g>
						  </g>
						  <use
							xlinkHref="#mask"
							style={{
							  overflow: "visible",
							  fill: "none",
							  stroke: "#bababa",
							  strokeWidth: 14,
							  strokeMiterlimit: 10
							}}
						  />
						</g>
					  </svg>
					</div>
					<div className="gp">
					  <div className="title1 text-center">
						<h2 className="banner">
						  <span>
							<em>Trabajos</em> Actuales
						  </span>
						</h2>
					  </div>
					  <div className="container">
						<div className="rmv-ext5">
						  <div className="prtnr-bx">
							<div className="row row-1">
							  <div className="col-md-5 col-sm-12 col-lg-5">
								<div className="prtnr-thmb rd5 text-center">
								  <a
									href="https://www.disegnowebologna.it/"
									title=""
									itemProp="url"
								  >
									<img
									  src="https://www.disegnowebologna.it/wp-content/uploads/2020/10/dwb_6.png"
									  height="50px"
									  alt="Disegno web Bologna"
									  itemProp="image"
									/>
								  </a>
								</div>
							  </div>
							  <div className="col-md-7 col-sm-12 col-lg-7">
								<div className="prtnr-inf">
								  <h2 itemProp="headline">Disegno Web Bologna</h2>
								  <span>
									Co-Ceo | Diseño &amp; Web Development / Gráfico /
									Marketing Digital
								  </span>
								  <p
									itemProp="description"
									style={{
									  fontWeight: 600,
									  color: "#dd2abf",
									  maxWidth: 200,
									  margin: "10px auto"
									}}
								  >
									2015 - Actualidad
								  </p>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="prtnr-bx">
							<div className="row row-1">
							  <div className="col-md-5 col-sm-12 col-lg-5 pull-right">
								<div className="prtnr-thmb rd5 text-center">
								  <a
									href="https://webmarketingconsulenza.com/"
									title=""
									itemProp="url"
								  >
									<img
									  src="logo-WM-sas-2022-ombra.png"
									  alt="Web Marketing consulenza"
									  itemProp="image"
									  height="150px"
									/>
								  </a>
								</div>
							  </div>
							  <div className="col-md-7 col-sm-12 col-lg-7">
								<div className="prtnr-inf">
								  <h2 itemProp="headline">WM sas</h2>
								  <span>Freelance | Web Development e SEO manager</span>
								  <p
									itemProp="description"
									style={{
									  fontWeight: 600,
									  color: "#dd2abf",
									  maxWidth: 200,
									  margin: "10px auto"
									}}
								  >
									2022 - Actualidad
								  </p>
								</div>
							  </div>
							</div>
						  </div>
						  <div className="prtnr-bx">
							<div className="row row-1">
							  <div className="col-md-5 col-sm-12 col-lg-5">
								<div className="prtnr-thmb rd5 text-center">
								  <a
									href="https://www.25watts.com.ar/"
									title=""
									itemProp="url"
								  >
									<img
									  src="https://25watts.com.ar/assets/img/logo-25w-white.svg"
									  height="50px"
									  alt="25 watts"
									  itemProp="image"
									/>
								  </a>
								</div>
							  </div>
							  <div className="col-md-7 col-sm-12 col-lg-7">
								<div className="prtnr-inf">
								  <h2 itemProp="headline">25 Watts</h2>
								  <span>
									Freelance | Diseño &amp; Web Development / Gráfico
								  </span>
								  <p
									itemProp="description"
									style={{
									  fontWeight: 600,
									  color: "#dd2abf",
									  maxWidth: 200,
									  margin: "10px auto"
									}}
								  >
									2019 - Actualidad
								  </p>
								</div>
							  </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </section>
				  <section className="bottom">
					<div className="gp no-gp">
					  <div className="row mrg">
						<div className="col-md-6 col-sm-12 col-lg-6">
						  <div className="cnt-inf-wrp text-center gry-bg2">
							<h4 itemProp="headline">Info</h4>
							<p itemProp="description">
							  Nacionalidad: Argentina, Viviendo en Córdoba | Fecha de
							  nacimiento: 17/01/1984.
							</p>
							<br />
							<button
							  onClick={handleClick}
							  className="btn"
							  target="_blank"
							  style={{
								background: "#ff0959",
								display: "flex",
								justifyContent: "center",
								margin: "0 auto"
							  }}
							>
							  <div
								style={{
								  paddingTop: 6,
								  float: "right",
								  color: "#fff",
								  paddingLeft: 4
								}}
							  >
								SOLICITAR PRESUPUESTO ONLINE
							  </div>
							</button>
							<div className="cnt-gal">
							  <div className="row mrg">
								<div className="scl1">
								  <a
									href="https://www.instagram.com/charlescba/"
									title="Google Plus"
									itemProp="url"
									target="_blank"
								  >
									<i className="fa fa-instagram" />
								  </a>
								  <a
									href="https://www.facebook.com/juancMancha"
									title="Facebook"
									itemProp="url"
									target="_blank"
								  >
									<i className="fa fa-facebook-square" />
								  </a>
								  <a
									href="https://www.linkedin.com/in/juan-carlos-mancha-ramos-36b57334/"
									title="linkedin"
									itemProp="url"
									target="_blank"
								  >
									<i className="fa fa-linkedin" />
								  </a>
								</div>
							  </div>
							</div>
							<div className="cnt-inf-inr">
							  <div className="row">
								<div className="col-md-6 col-sm-6 col-lg-6">
								  <ul className="opn-lst text-right">
									<li>
									  <i className="fa fa-clock-o" />{" "}
									  <span>
										<strong>Lun - Vier:</strong> 8:00-12:00,
									  </span>
									</li>
									<li>
									  <i className="fa fa-clock-o" />{" "}
									  <span>
										<strong>Sabado:</strong> 8:00-16:00,
									  </span>
									</li>
								  </ul>
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6">
								  <div className="cnt-inf-innr">
									<button
									  onClick={handleClick1}
									  className="btn whatsapp"
									  target="_blank"
									>
									  <i
										className="fa fa-whatsapp whatsapp icon-large"
										style={{ fontSize: 25, color: "#fff" }}
									  />
									  <div
										style={{
										  paddingTop: 6,
										  float: "right",
										  color: "#fff",
										  paddingLeft: 4
										}}
									  >
										WHATSAPP
									  </div>
									</button>
									<p itemProp="description">Córdoba | Argentina</p>
								  </div>
								</div>
							  </div>
							</div>
						  </div>
						</div>
						<div className="col-md-6 col-sm-12 col-lg-6">
						  <div className="cnt-inf-wrp text-center gry-bg3">
							<h4 itemProp="headline">Consulta</h4>
							<form
							  id="contact_form"
							  action="contact1/contact.php"
							  method="post"
							>
							  <div className="row mrg20">
								<div className="col-md-6 col-sm-6 col-lg-6">
								  <label>Nombre</label>
								  <input
									className="rd5"
									name="nombre"
									type="text"
									placeholder="Sr o Sra"
								  />
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6">
								  <label>Telefono</label>
								  <input
									className="rd5"
									name="telefono"
									type="text"
									placeholder="+51 0351"
								  />
								</div>
								<div className="col-md-6 col-sm-6 col-lg-6">
								  <label>Email</label>
								  <input
									className="rd5"
									name="correo"
									type="email"
									placeholder=""
								  />
								</div>
								<div className="col-md-12 col-sm-12 col-lg-12">
								  <label>Mensaje</label>
								  <textarea
									name="mensaje"
									className="rd5"
									defaultValue={""}
								  />
								</div>
								<div className="boton-enviar col-md-12 col-sm-12 col-lg-12">
								  <button
									type="submit"
									className="rd2 btn1 ylw-bg"
									id="submit_btn_6"
									style={{ padding: "15px !important" }}
								  >
									<p
									  className="loading"
									  style={{ display: "none", margin: 0 }}
									/>
									<p className="enviar" style={{ margin: 0 }}>
									  Enviar
									</p>
								  </button>
								</div>
							  </div>
							  <div
								id="result"
								className="alert alert-success"
								role="alert"
							  >
								Mensaje enviado, Muchas gracias :)
							  </div>
							</form>
						  </div>
						</div>
					  </div>
					</div>
				  </section>
				</div>
				<div className="japan-block">
				  <a href="#">ウェブデザインボローニャ</a>
				</div>
				<div className="section copyright">
				  <p>
					©<span id="year">2024</span> Juan Carlos Mancha Ramos
				  </p>
				</div>
				<div
				  className="section companylinks"
				  style={{
					background: "url(assets/images/footer.png)",
					backgroundPosition: "bottom",
					height: 110
				  }}
				>
				  <ul className="companies" style={{ display: "none" }}>
					<li className="campo">
					  <a href="#">
						<h1>Italia</h1>
					  </a>
					</li>
					<li className="panic">
					  <a href="#">
						<h1>España</h1>
					  </a>
					</li>
				  </ul>
				</div>
			  </div>
			</div>
		  </main>
		</div>
  );
}

export default App;



